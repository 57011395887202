/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PagesDisplayPersistenceOption {
  CARDS = "CARDS",
  COMPACT_LIST = "COMPACT_LIST",
  LIST = "LIST",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SpaceBlogsAppearanceMutation
// ====================================================

export interface SpaceBlogsAppearanceMutation_updateUserPreferences {
  globalPageCardAppearancePreference: PagesDisplayPersistenceOption;
}

export interface SpaceBlogsAppearanceMutation {
  updateUserPreferences: SpaceBlogsAppearanceMutation_updateUserPreferences;
}

export interface SpaceBlogsAppearanceMutationVariables {
  persistenceOption: PagesDisplayPersistenceOption;
}
