import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';
import { NetworkStatus } from 'apollo-client';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Spinner from '@atlaskit/spinner/spinner';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import { PageCards } from '@confluence/page-card';
import { ErrorDisplay } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { GenericError, PaginationError, isExpectedError } from '@confluence/space-pages';
import { ViewportObserver } from '@confluence/viewport-observer';
import {
	ExperienceFailure,
	ExperienceSuccess,
	SPACE_BLOGS_EXPERIENCE,
} from '@confluence/experience-tracker';
import { PageLoadEnd } from '@confluence/browser-metrics';

import { transformData } from './transformBlogData';
import { Empty } from './Empty';
import { SpaceBlogsQuery } from './SpaceBlogsQuery.graphql';
import { SpaceBlogsContext, BlogsAllPillFilter, BLOG_STATUSES } from './SpaceBlogsContext';
import { SpaceBlogsCollapsibleSection } from './SpaceBlogsCollapsibleSection';
import type { SpaceBlogs as SpaceBlogsType } from './__types__/SpaceBlogsQuery';
import { YearPillFilter } from './YearPillFilter';
import { useSpaceBlogsPagination } from './useSpaceBlogsPagination';
import { SPACE_BLOGS_PAGE_LOAD } from './perf.config';

const PAGE_SIZE = 24;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SearchResultsContainer = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PaginationSpinnerContainer = styled.div({
	width: '100%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '30px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '30px',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PillsPlaceholder = styled.div({
	width: '100%',
	height: '68px',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const YearPillFiltersContainer = styled.div({
	marginBottom: token('space.500', '40px'),
	display: 'flex',
	flexWrap: 'wrap',
});

type SearchResultsProps = {
	spaceKey?: string;
};

export const SearchResults = ({ spaceKey }: SearchResultsProps) => {
	const {
		appearance,
		persistenceQueryLoading,
		selectedYearPill,
		totalCount,
		setTotalCount,
		setSearchResults,
		searchResults,
	} = useContext(SpaceBlogsContext);
	const [nextPageError, setNextPageError] = useState<ApolloError>();
	const [yearPills, setYearPills] = useState<JSX.Element[]>([]);
	const [pageLoadStopTime, setPageLoadStopTime] = useState<number | null>(null);
	const refetchedQueryRef = useRef<boolean>(false);

	const isShowingBlankDraftsEnabled = useBooleanFeatureFlag(
		'confluence.frontend.show-blank-drafts',
	);

	const {
		error: firstPageError,
		data,
		loading,
		networkStatus,
		fetchMore,
		refetch,
	} = useQuery<SpaceBlogsType>(SpaceBlogsQuery, {
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		variables: {
			spaceKey,
			first: PAGE_SIZE,
			statuses: BLOG_STATUSES,
		},
	});

	const { shouldPaginate, nextPage } = useSpaceBlogsPagination({
		data,
		fetchMore,
		loading,
		spaceKey,
		setNextPageError,
	});

	const emptyResults = Object.keys(searchResults).length === 0;
	const error = useMemo(() => {
		if (firstPageError) {
			if (isExpectedError(firstPageError)) {
				markErrorAsHandled(firstPageError);
			} else {
				return firstPageError;
			}
		}
		if (nextPageError) {
			if (isExpectedError(nextPageError)) {
				markErrorAsHandled(nextPageError);
			} else {
				return nextPageError;
			}
		}
		return undefined;
	}, [firstPageError, nextPageError]);

	useEffect(() => {
		if (!loading) {
			setPageLoadStopTime(performance.now());
		}
	}, [loading, setPageLoadStopTime]);

	// useQuery for SpaceBlogsQuery doesn't make a network request to the server even with fetchPolicy: cache-and-network
	// likely an apollo bug, this is a workaround that allows sending the network request to fetch new blogs data
	useEffect(() => {
		if (!loading && !refetchedQueryRef.current) {
			setTimeout(() => refetch(), 0);
			refetchedQueryRef.current = true;
		}
	}, [loading, refetch]);

	const intl = useIntl();
	useEffect(() => {
		const { searchResultsByYear, totalCount } = transformData(
			data,
			spaceKey,
			intl.formatMessage(i18n.untitled),
			isShowingBlankDraftsEnabled,
		);
		setTotalCount(totalCount || 0);
		setSearchResults(searchResultsByYear);
	}, [data, setSearchResults, setTotalCount, spaceKey, intl, isShowingBlankDraftsEnabled]);

	useEffect(() => {
		const pills = Object.keys(searchResults)
			.reverse()
			.map((key) => <YearPillFilter filter={key} key={key} count={searchResults[key].length} />);
		setYearPills(pills);
	}, [searchResults, selectedYearPill]);

	const spaceBlogsContent = useMemo(() => {
		return Object.keys(searchResults)
			.filter((year) => selectedYearPill == BlogsAllPillFilter || year == selectedYearPill)
			.reverse()
			.map((year) => (
				<SpaceBlogsCollapsibleSection year={year} key={year}>
					<PageCards
						appearance={appearance}
						nodes={searchResults[year]}
						analyticsData={{
							source: 'spaceBlogsScreen',
						}}
					/>
				</SpaceBlogsCollapsibleSection>
			));
	}, [searchResults, selectedYearPill, appearance]);

	return (
		<SearchResultsContainer>
			{!error ? (
				yearPills.length > 0 ? (
					<YearPillFiltersContainer>
						<YearPillFilter filter={BlogsAllPillFilter} count={totalCount} />
						{yearPills}
					</YearPillFiltersContainer>
				) : (
					<PillsPlaceholder />
				)
			) : null}

			{!persistenceQueryLoading && !error ? (
				<>
					{spaceBlogsContent}
					<ExperienceSuccess name={SPACE_BLOGS_EXPERIENCE} />
				</>
			) : null}

			{/*empty states*/}
			{networkStatus === NetworkStatus.ready && emptyResults && !error ? <Empty /> : null}

			{/*pagination*/}
			{networkStatus === NetworkStatus.loading || // query has never been run before and the query is now currently running
			(shouldPaginate && !error) ? ( // render the spinner even when not loading to prevent jumping
				<>
					<PaginationSpinnerContainer>
						<Spinner size="large" testId="pagination-spinner" />
					</PaginationSpinnerContainer>

					{/* Pagination sentinel - when this scrolls into view, the next page loads. If there is no next page, don't need to render */}
					<ViewportObserver onViewportEnter={nextPage}>
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={{ position: 'absolute', bottom: '0', height: '300px' }}
						/>
					</ViewportObserver>
				</>
			) : null}

			{/*error states*/}
			{error ? (
				<ErrorDisplay error={error}>
					<ExperienceFailure error={error} name={SPACE_BLOGS_EXPERIENCE} />
					{nextPageError || searchResults.length ? <PaginationError /> : <GenericError />}
				</ErrorDisplay>
			) : null}

			{pageLoadStopTime && (
				<PageLoadEnd metric={SPACE_BLOGS_PAGE_LOAD} stopTime={pageLoadStopTime} />
			)}
		</SearchResultsContainer>
	);
};

const i18n = defineMessages({
	untitled: {
		id: 'space-blogs.untitled',
		defaultMessage: 'Untitled',
		description: 'The title for a blog post that has no title',
	},
});
