import type { FC } from 'react';
import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { B50, B400, N20A, N30, N500 } from '@atlaskit/theme/colors';

import { SpaceBlogsContext } from './SpaceBlogsContext';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PillButton = styled.button<{
	isSelected?: boolean;
}>({
	outline: 'none',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '5px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.isSelected
			? token('color.background.selected', B50)
			: token('color.background.neutral', N20A),
	display: 'inline-flex',
	fontSize: '14px',
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isSelected ? token('color.text.selected', B400) : token('color.text', N500),
	cursor: 'pointer',
	userSelect: 'none',
	marginRight: token('space.100', '8px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	verticalAlign: 'top',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.isSelected
				? token('color.background.selected.hovered', B50)
				: token('color.background.neutral.hovered', N30),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: (props) =>
		props.isSelected
			? // TODO: Change B400 here to B700 when it becomes available
				`1px solid ${token('color.border.focused', B400)}`
			: `1px solid ${token('color.background.neutral', N20A)}`,
	borderRadius: '100px',
	height: '32px',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '48px',
	'&:focus': {
		outline: `2px solid ${token('color.border.focused', B400)}`,
		borderColor: 'transparent',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CountContainer = styled.span({
	fontWeight: 700,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterContainer = styled.span({
	marginRight: token('space.075', '6px'),
});

type YearPillFilterProps = {
	filter: string;
	count?: number;
};

export const YearPillFilter: FC<YearPillFilterProps> = ({ filter, count = 0 }) => {
	const { selectedYearPill, setSelectedYearPill } = useContext(SpaceBlogsContext);
	const isSelected = filter == selectedYearPill;

	const handleClick = (filter: string) => () => {
		setSelectedYearPill(filter);
	};

	return (
		<PillButton
			data-testid="space-blogs-year-pill"
			onClick={handleClick(filter)}
			isSelected={isSelected}
		>
			<FilterContainer aria-label={`year ${filter}`}>{filter}</FilterContainer>
			<CountContainer aria-label={`${count} blogs`}>{count}</CountContainer>
		</PillButton>
	);
};
