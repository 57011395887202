import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import EmptyStateImage from './assets/EmptyStateImage.svg';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyContainer = styled.div({
	marginTop: token('space.800', '64px'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyHeader = styled.h3({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '35px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyBody = styled.div({
	marginTop: token('space.200', '16px'),
	width: '395px',
	textAlign: 'center',
});

const i18n = defineMessages({
	emptyHeader: {
		id: 'space-blogs.search.results.empty.state.header',
		defaultMessage: 'Bring this space to life',
		description:
			'Descriptive header that appears on the Blogs screen when no Blogs exist in a space.',
	},
	emptyBody: {
		id: 'space-blogs.search.results.empty.state.body',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "There aren't any blogs in this space yet",
		description:
			'Descriptive body text that appears on the Blogs screen when no Blogs exist in a space.',
	},
});

export const Empty: FC = () => (
	<EmptyContainer>
		<img src={EmptyStateImage} alt="" />
		<EmptyHeader>
			<FormattedMessage {...i18n.emptyHeader} />
		</EmptyHeader>
		<EmptyBody>
			<FormattedMessage {...i18n.emptyBody} />
		</EmptyBody>
	</EmptyContainer>
);
