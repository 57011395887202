import { useCallback, useContext } from 'react';

import {
	SPACE_BLOGS_PAGINATE_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import type { SpaceBlogs as SpaceBlogsType } from './__types__/SpaceBlogsQuery';
import { SpaceBlogsContext, BlogsAllPillFilter, BLOG_STATUSES } from './SpaceBlogsContext';

const PAGINATION_PAGE_SIZE = 24;

export const useSpaceBlogsPagination = ({
	data,
	loading,
	fetchMore,
	spaceKey,
	setNextPageError,
}) => {
	const { selectedYearPill, searchResults } = useContext(SpaceBlogsContext);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { hasNextPage, endCursor } = data?.content?.pageInfo || {};

	const nextPage = useCallback(async () => {
		if (hasNextPage && endCursor && !loading) {
			experienceTracker.start({ name: SPACE_BLOGS_PAGINATE_EXPERIENCE });

			const { errors } = await fetchMore({
				variables: {
					spaceKey,
					first: PAGINATION_PAGE_SIZE,
					statuses: BLOG_STATUSES,
					after: endCursor,
				},
				updateQuery: (prevResult: SpaceBlogsType, { fetchMoreResult }) => {
					if (!fetchMoreResult?.content) {
						return prevResult;
					}
					fetchMoreResult.content.nodes = (prevResult?.content?.nodes || []).concat(
						fetchMoreResult?.content?.nodes || [],
					);
					experienceTracker.succeed({ name: SPACE_BLOGS_PAGINATE_EXPERIENCE });
					return fetchMoreResult;
				},
			});
			if (errors) {
				experienceTracker.fail({
					error: errors,
					name: SPACE_BLOGS_PAGINATE_EXPERIENCE,
				});
				setNextPageError({
					name: 'PaginationError',
					message: errors.map((e) => e.message).join('\n'),
					graphQLErrors: errors,
					networkError: null,
					extraInfo: null,
				});
			}
		}
	}, [hasNextPage, endCursor, loading, fetchMore, spaceKey, setNextPageError, experienceTracker]);

	// when a user filters by a year, we should only paginate if we aren't showing them all the blogs for that year yet. It should stop paginating once we get all the children for that year.
	// when a user scrolls to the bottom of all space blogs, it should always paginate for more blogs if there are more blogs to load
	const shouldPaginate =
		hasNextPage &&
		(selectedYearPill == BlogsAllPillFilter ||
			(selectedYearPill != BlogsAllPillFilter &&
				Number(selectedYearPill) === Math.min(...Object.keys(searchResults).map(Number))));

	return {
		shouldPaginate,
		nextPage,
	};
};
